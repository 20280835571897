
@import "~@library/theme";


.wrapper {

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  background: rgba($black, .3);
  z-index: 10;
  overflow: auto;

  &__content {

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    overflow: auto;

    &--align_top {

      justify-content: flex-start;
    }
  }
}

.dialog {

  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  background: $white;
  min-width: 240px;
  min-height: 100px;
  border-radius: 20px;
  margin: 20px 0;
  padding: 42px 84px;
  box-shadow: 0 6px 10px 0 rgba(34, 34, 34, 0.3);

  @media (max-width: $mobile) {

    padding: 32px;
  }

  &--info { background: lighten($info, 30%); }
  &--info &__header { background: $info;  }

  &--primary { background: lighten($blue, 40%); }
  &--primary &__header { background: $blue; color: $white; }
  &--primary &__close:hover { color: $white; }

  &--success { background: lighten($green, 40%); }
  &--success &__header { background: $green; color: $white; }
  &--success &__close:hover { color: $white; }

  &--danger { background: lighten($danger, 40%); }
  &--danger &__header { background: $danger; color: $white; }
  &--danger &__close:hover { color: $white; }


  &__close {

    position: absolute;
    top: 24px;
    right: 24px;
    display: block;
    font-size: 24px;
    cursor: pointer;
    opacity: .4;
    transition: opacity 200ms;

    &:hover {
      opacity: 1;
    }
  }

  &__header {

    display: block;
    padding: 0 0 30px;
    text-align: center;
  }

  &__title {

    font-size: 16px;
    font-weight: 500;
    color: $dark;
  }

  &__content {

    display: block;
  }
}
